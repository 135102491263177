import Log from "../../../src/Log";
const path = require("path");
const envPath = path.resolve(__dirname, "client/.env");
require("dotenv").config({ path: envPath });

class makePDFDataContainer {
  public PDFDocument;
  public blobStream;
  public iframe;
  public texts;
  public uploader;
  public formValues;
  public shouldUpload;
  public showPreview;

  constructor(
    PDFDocument,
    blobStream,
    iframe,
    texts,
    uploader,
    formValues,
    shouldUpload,
    showPreview
  ) {
    this.PDFDocument = PDFDocument;
    this.blobStream = blobStream;
    this.iframe = iframe;
    this.texts = texts;
    this.uploader = uploader;
    this.formValues = formValues;
    this.shouldUpload = shouldUpload;
    this.showPreview = showPreview;
  }
}

export default class makePDF {
  //INFO: if you don't see any pictures rendered it might be caused by a missing realUrl
  private testUrl = process.env.PUBLIC_URL+`/server/productions/aaaTest.png`;

  constructor(
    PDFDocument,
    blobStream,
    iframe,
    texts,
    uploader,
    formValues,
    shouldUpload,
    showPreview
  ) {
    const dataContainer = new makePDFDataContainer(
      PDFDocument,
      blobStream,
      iframe,
      texts,
      uploader,
      formValues,
      shouldUpload,
      showPreview
    );

    let realUrl = process.env.PUBLIC_URL+`/server/productions/${formValues.uploadImageMetaData.name}`;
    let url = realUrl;

    this.getDataUri(url, dataContainer, (dataContainer, dataUri) =>
      this.generatePDF(dataContainer, dataUri)
    );
  }

  private getDataUri(url, dataContainer, callback) {
    var image = new Image();

    image.crossOrigin = "anonymous";

    image.onload = function () {
      var canvas = document.createElement("canvas");
      canvas.width = image.naturalWidth; // or 'width' if you want a special/scaled size
      canvas.height = image.naturalHeight; // or 'height' if you want a special/scaled size

      Log.d("canvas.width: " + canvas.width + "canvas.height " + canvas.height);
      canvas.getContext("2d").drawImage(image, 0, 0);

      // // Get raw image data
      // callback(canvas.toDataURL('image/png').replace(/^data:image\/(png|jpg);base64,/, ''));

      // ... or get as Data URI
      if (
        url.includes(".jpg") ||
        url.includes(".jpeg") ||
        url.includes(".JPG") ||
        url.includes(".JPEG")
      ) {
        Log.d("image/jpeg");
        callback(dataContainer, canvas.toDataURL("image/jpeg"));
      } else if (url.includes(".png") || url.includes(".PNG")) {
        Log.d("image/png");
        callback(dataContainer, canvas.toDataURL("image/png"));
      }
    };

    image.src = url;
  }

  private generatePDF(dataContainer, dataUri) {
    // create a document and pipe to a blob
    var doc = new dataContainer.PDFDocument({
      size: [595.28, 841.89],
      margins: {
        top: 50,
        bottom: 50,
        left: 50,
        right: 20,
      },
    });
    var stream = doc.pipe(dataContainer.blobStream()); //doc.addPage();

    doc.registerFont("HeadingFont", "fonts/SuisseIntl-Regular.ttf");
    doc.registerFont("HeadingFontFat", "fonts/SuisseIntl-Bold.ttf");
    doc.registerFont("TextFont", "fonts/SuisseNeue-Regular.ttf");

    let pageCounter = 1;

    const drawHeader = (doc) => {
      //HEADER START
      doc.image("images/WIENXTRA-wortmarke-CMYK-2c.png", 56, 36, {
        width: 254,
      });

      doc.image("images/WIENXTRA-template-briefpapier-Weltkugel.png", 445, 55, {
        width: 10,
      });

      doc
        .fillColor("#000000")
        .fontSize(14)
        .font("HeadingFont")
        .text("WIENXTRA.AT", 460, 51, {
          characterSpacing: 0.5,
        });
      doc
        .moveUp("2")
        .fillColor("#FF0000")
        .font("HeadingFont")
        .text("#FÜREUCH", 477, 35, {
          characterSpacing: 0.5,
        });

      doc.fillColor("#000000").font("HeadingFont").text("", 50, 100, {
        width: 500,
        align: "left",
        indent: 30,
        columns: 1,
        height: 10,
        ellipsis: false,
      });
      //HEADER END
    };

    const drawFooter = (doc) => {
      pageCounter++;
      doc.text(`-${pageCounter}-`, 290, doc.page.height - 50, {
        lineBreak: false,
      });
    };

    drawHeader(doc);

    doc
      .fillColor("#000000")
      .fontSize(18)
      .font("HeadingFontFat")
      .text(dataContainer.texts.pdfHeadline1, {
        width: 500,
        align: "left",
      });
    doc
      .fillColor("#000000")
      .fontSize(9)
      .moveDown()
      .font("TextFont")
      .text(dataContainer.texts.conditionsOfParticipation, {
        width: 500,
        align: "left",
        indent: 5,
        columns: 1,
        height: 200,
        ellipsis: false,
      });

    doc
      .font("HeadingFont")
      .fontSize(14)
      .moveDown()
      .text(dataContainer.texts.titleSection1)
      .font("TextFont")
      .fontSize(12)
      .text(dataContainer.formValues.productionTitle)

      .font("HeadingFont")
      .fontSize(12)
      .moveDown()
      .text(dataContainer.texts.titleSection2)
      .font("TextFont")
      .fontSize(9)
      .text(
        dataContainer.formValues.participantsCollection.map(
          (elem, index) =>
            " " +
            (index + 1) +
            ": " +
            elem.firstName +
            ", " +
            elem.lastName +
            ", " +
            elem.birthdate
        )
      )
      .font("HeadingFont")
      .fontSize(12)
      .moveDown()
      .text(dataContainer.texts.titleSection3)
      .font("TextFont")
      .fontSize(9)
      .text(
        dataContainer.formValues.firstNameMainContact +
          ", " +
          dataContainer.formValues.lastNameMainContact
      )

      .font("HeadingFont")
      .fontSize(12)
      .moveDown()
      .text(dataContainer.texts.addressMainContactLabel.toUpperCase())
      .font("TextFont")
      .fontSize(9)
      .text(
        dataContainer.formValues.addressMainContactStreetAndNumber +
          ", " +
          dataContainer.formValues.addressMainContactPostcode +
          ", " +
          dataContainer.formValues.addressMainContactPlace +
          ", " +
          dataContainer.formValues.addressMainContactCountry
      )

      .font("HeadingFont")
      .fontSize(12)
      .moveDown()
      .text(dataContainer.texts.phoneMainContactLabel.toUpperCase())
      .font("TextFont")
      .fontSize(9)
      .text(
        dataContainer.formValues.prefix +
          " " +
          dataContainer.formValues.phoneMainContact
      )

      .font("HeadingFont")
      .fontSize(12)
      .moveDown()
      .text(dataContainer.texts.emailMainContactLabel.toUpperCase())
      .font("TextFont")
      .fontSize(9)
      .text(dataContainer.formValues.emailMainContact);

    doc
      .font("HeadingFont")
      .fontSize(16)
      .moveDown()
      .text(dataContainer.texts.titleSection4)

      .font("HeadingFont")
      .fontSize(12)
      .moveDown()
      .text(dataContainer.texts.productionLengthLabel.toUpperCase())
      .font("TextFont")
      .fontSize(9)
      .text(dataContainer.formValues.productionLength)

      .font("HeadingFont")
      .fontSize(12)
      .moveDown()
      .text(dataContainer.texts.productionTimeLabel.toUpperCase())
      .font("TextFont")
      .fontSize(9)
      .text(dataContainer.formValues.productionTime)

      .font("HeadingFont")
      .fontSize(12)
      .moveDown()
      .text(dataContainer.texts.productionShortSummaryLabel.toUpperCase())
      .fontSize(7)
      .text(" (" + dataContainer.texts.productionShortSummaryPlaceholder + ") ")
      .moveDown()
      .font("TextFont")
      .fontSize(9)
      .text(dataContainer.formValues.productionShortSummary)

      .font("HeadingFont")
      .fontSize(12)
      .moveDown()
      .text(
        dataContainer.texts.productionParticipantsDescriptionLabel.toUpperCase()
      )
      .fontSize(7)
      .text(
        " (" +
          dataContainer.texts.productionParticipantsDescriptionPlaceholder +
          ") "
      )
      .moveDown()
      .font("TextFont")
      .fontSize(9)
      .text(dataContainer.formValues.productionParticipantsDescription);

    doc.addPage();

    doc
      .font("HeadingFont")
      .fontSize(12)
      .moveDown()
      .text(dataContainer.texts.helpReceivedQuestion.toUpperCase())
      .fontSize(7)
      .text(
        " (" +
          dataContainer.texts.productionParticipantHelpersPlaceholder +
          ") "
      )
      .moveDown()
      .font("TextFont")
      .fontSize(9)
      .text(
        dataContainer.formValues.productionParticipantHelpers !== undefined
          ? dataContainer.texts.yes +
              ", \n" +
              dataContainer.formValues.productionParticipantHelpers
          : dataContainer.texts.no
      );

    doc
      .font("HeadingFont")
      .fontSize(12)
      .moveDown()
      .text(dataContainer.texts.hasFurtherProjectsPlannedQuestion.toUpperCase())
      .fontSize(7)
      .text(
        " (" + dataContainer.texts.furtherProjectsDescriptionPlaceholder + ") "
      )
      .moveDown()
      .font("TextFont")
      .fontSize(9)
      .text(
        dataContainer.formValues.furtherProjectsDescription !== undefined
          ? dataContainer.texts.yes +
              ", \n" +
              dataContainer.formValues.furtherProjectsDescription
          : dataContainer.texts.no
      );

    doc
      .font("HeadingFont")
      .fontSize(12)
      .moveDown()
      .text(
        dataContainer.texts.hasPublicContactInformationQuestion.toUpperCase()
      )
      .font("TextFont")
      .fontSize(9)
      .moveDown()
      .text(
        dataContainer.texts.emailPublicLabel +
          ": " +
          (dataContainer.formValues.emailPublic !== undefined
            ? dataContainer.formValues.emailPublic
            : dataContainer.texts.no)
      )
      .font("TextFont")
      .fontSize(9)
      .text(
        dataContainer.texts.websitePublicLabel +
          ": " +
          (dataContainer.formValues.websitePublic !== undefined
            ? dataContainer.formValues.websitePublic
            : dataContainer.texts.no)
      );

    doc
      .font("HeadingFont")
      .fontSize(14)
      .moveDown()
      .text(dataContainer.texts.titleSection5);

    doc
      .font("TextFont")
      .fontSize(7)
      .moveDown()
      .text(dataContainer.texts.newsLetterAgreement, {
        width: 500,
        align: "justify",
        indent: 5,
        columns: 1,
        height: 200,
        ellipsis: false,
      })
      .moveDown()
      .font("TextFont")
      .fontSize(9)
      .text(
        dataContainer.texts.newLetterAgreementLabel +
          " " +
          dataContainer.formValues.newLetterAgreement
          ? dataContainer.texts.yes
          : dataContainer.texts.no
      );

	  doc
      .font("HeadingFont")
      .fontSize(14)
      .moveDown()
      .text(dataContainer.texts.titleSection7);
	  
    doc
      .font("TextFont")
      .fontSize(7)
      .moveDown()
      .text(dataContainer.texts.presenceAgreementPart1 + 
	    dataContainer.texts.presenceAgreementPart2 + 
		dataContainer.texts.presenceAgreementPart3 + 
		dataContainer.texts.presenceAgreementPart4 + 
		dataContainer.texts.presenceAgreementPart5, {
        width: 500,
        align: "justify",
        indent: 5,
        columns: 1,
        height: 200,
        ellipsis: false,
      })	  
	  
	  
	  doc
      .font("HeadingFont")
      .fontSize(14)
      .moveDown()
      .text(dataContainer.texts.titleSection6);

    doc
      .font("TextFont")
      .fontSize(7)
      .moveDown()
      .text(dataContainer.texts.participationConditionsAgreementPart2, {
        link: "https://www.wienxtra.at/datenschutz/",
        underline: true,
      })
      .moveDown()
      .text(
        dataContainer.texts.participationConditionsAgreementPart1 +
          " " +
          dataContainer.texts.participationConditionsAgreementPart2 +
          " " +
          dataContainer.texts.participationConditionsAgreementPart3,
        {
          width: 500,
          align: "left",
          indent: 5,
          columns: 1,
          height: 200,
          ellipsis: false,
        }
      )
      .font("TextFont")
      .fontSize(9)
      .moveDown()
      .text(
        dataContainer.texts.conditionsOfParticipationLabel +
          " " +
          dataContainer.formValues.conditionsOfParticipation
          ? dataContainer.texts.yes
          : dataContainer.texts.no
      )
      .font("TextFont")
      .fontSize(9)
      .moveDown()
      .text("Wien, am" + " " + dataContainer.texts.currentDate)
      .moveDown()
      .text(" ");

    doc.image(dataUri, {
      width: 180,
    });

    drawFooter(doc);

    doc.end();

    if (dataContainer.shouldUpload) {
      Log.d("shouldUpload... ");
      stream.on("finish", function () {
        let blob = stream.toBlob("application/pdf");
        Log.d("shouldUpload: " + blob);
        if (blob !== null) {
          var data = new FormData();
          data.append(
            "file",
            blob,
            dataContainer.formValues.productionTitle +
              "_" +
              dataContainer.formValues.firstNameMainContact +
              "_" +
              dataContainer.formValues.lastNameMainContact +
              ".pdf"
          );
          Log.d("appended blob: " + blob);
          dataContainer.uploader.add(data.get("file"));
        }
      });
    } else if (dataContainer.shouldUpload && dataContainer.showPreview) {
      Log.d("shouldUpload and showPreview...");
      stream.on("finish", function () {
        let blob = stream.toBlob("application/pdf");
        Log.d("shouldUpload and showPreview " + blob);
        if (blob !== null) {
          var data = new FormData();
          data.append(
            "file",
            blob,
            dataContainer.formValues.productionTitle +
              "_" +
              dataContainer.formValues.firstNameMainContact +
              "_" +
              dataContainer.formValues.lastNameMainContact +
              ".pdf"
          );
          Log.d("appended blob: " + blob);
          dataContainer.uploader.add(data.get("file"));
        }
        dataContainer.iframe.src = stream.toBlobURL("application/pdf");
      });
    } else if (!dataContainer.shouldUpload && dataContainer.showPreview) {
      Log.d("!shouldUpload && showPreview");
      stream.on("finish", function () {
        dataContainer.iframe.src = stream.toBlobURL("application/pdf");
      });
    }
  }
}
